import React, { useState } from 'react';
import { shortAddress } from '../eth-setup/apis';
import { getBlockchainData } from '../eth-setup/metamask-connection';

function ConnectWallet2({ qty, showButtons }) {
  const [account, setAccount] = useState(null);

  const connectHandler = async () => {
    getBlockchainData(async (account, web3) => {
      console.log({ account });
      setAccount(account);
      showButtons();
    });
  };

  return (
    <div className=''>
      <p className='' onClick={connectHandler}>
        {account ? <div className='mint__button'>{shortAddress(account)}</div> : <div className='mint__button'>CONNECT WALLET</div>}
      </p>
    </div>
  );
}

export default ConnectWallet2;
