import React, { useState, useRef, useEffect } from "react";
import ConnectWallet from "./ConnectWallet";
import ConnectWallet2 from "./ConnectWallet2";
export default function MintWhite({ setShow }) {
  const [mint, setMint] = useState("noButtons");
  const [audioStatus, changeAudioStatus] = useState(false);
  const [preMintText, afterMintText] = useState(true);

  const maxVal = 3;
  const [qty, setQty] = useState(1);

  // Play Audio
  const myRef = useRef();

  const startAudio = () => {
    myRef.current.play();
    //reduce speed of audio
    //reduce voice of audio

    myRef.current.playbackRate = 1;
    myRef.current.volume = 0.5;
    changeAudioStatus(true);
  };

  //toogle audio on off
  const toggleAudio = () => {
    if (audioStatus) {
      myRef.current.pause();
      changeAudioStatus(false);
    } else {
      myRef.current.play();
      changeAudioStatus(true);
    }
  };

  // Show Buttons
  const showButtons = () => {
    setMint("mintButtons");
    afterMintText(!preMintText);
    // startAudio();
  };

  useEffect(() => {
    startAudio();
  }, []);

  return (
    <div
      to="/mint"
      className="col-md-6 d-flex flex-column align-items-center justify-content-center bg-white mint__right"
    >
      {/* Audio */}
      <audio ref={myRef} loop src="/Images/music.mp3" />

      {/* Social Icons */}
      <div className="s__icons mt-1 flex align-items-center">
        <a
          href="https://twitter.com/TheRealHodlerz"
          target="_blank"
          rel="noreferrer"
          className="s_icon"
        >
          <img src="/Images/socials/twitter-icon.png" alt="twitter" />
        </a>
        <a
          href="https://opensea.io/collection/thehodlerz"
          target="_blank"
          rel="noreferrer"
          className="s_icon"
        >
          <img src="/Images/socials/opensea-icon.png" alt="logo" />
        </a>
        <span className="s_icon" onClick={toggleAudio}>
          {audioStatus ? (
            <img src="/Images/socials/pause.png" alt="||" />
          ) : (
            <img src="/Images/socials/play.png" alt="|>" />
          )}
        </span>
      </div>
      <h6 className="mt-2 col-4 text-end">THE HODLERZ</h6>

      {/* Red Box */}
      <img onClick={showButtons} src="/Images/red.png" alt="" />
      {/* <div className="text__small mt-3 text-center">
        <h4 className="m-0" onClick={showButtons}>
          Mint Here
        </h4>
        <h6>
          1 FREE TO MINT <br /> THEN O.01 ETH <br /> MAX 10X WALLET
        </h6>
      </div> */}

      {preMintText ? (
        <div className="text__small mt-3 text-center">
          <h4 className="m-0" onClick={showButtons}>
            MINT HERE
          </h4>
          <h6>
            0.02 ETH EACH <br />
            MAX 3 X WALLET <br />
            DROP INFO SOON <br />
            {/* THEN 0.00666 ETH <br />
            MAX 2x WALLET */}
          </h6>
        </div>
      ) : (
        <div className="text__small mt-3 text-center">
          <h4 className="m-0" onClick={showButtons}>
            INSTANT REVEAL
          </h4>
          <h6>
            DISCORD OPENING
            <br /> AFTER SOLD OUT ONLY <br />
            SMART CONTRACT{" "}
            <a
              href="https://etherscan.io/address/0x923f133df46e70c87f8a82b138ab4068cab59148#code"
              target="_blank"
              rel="noreferrer"
            >
              HERE
            </a>
            {/* . */}
          </h6>
        </div>
      )}

      {/* <div className="text__small mt-3 text-center">
        <h4 className="m-0" onClick={showButtons}>
          XXXXXX
        </h4>
        <h6>
          hjdgjf <br /> THEdsdsdsasdH <br /> MAXsdasad
        </h6>
      </div> */}

      {/* Input Buttons */}
      {/* <div className={mint}>
        <input
          min={0}
          value={qty}
          type="number"
          className="mint__button"
          onChange={(e) => {
            if (Number(e.target.value) > maxVal) setQty(maxVal);
            else if (e.target.value === "") setQty(e.target.value);
            else if (Number(e.target.value) < 1) setQty(1);
            else setQty(e.target.value);
          }}
          onClick={(e) => {
            if (Number(e.target.value) > maxVal) setQty(maxVal);
            else if (e.target.value === "") setQty(e.target.value);
            else if (Number(e.target.value) < 1) setQty(1);
            else setQty(e.target.value);
          }}
        />
        <ConnectWallet qty={qty} setShow={setShow} />
      </div> */}

      <ConnectWallet2 showButtons={showButtons} />
      {/* <div className="button1">
        <ConnectWallet />
      </div>
      <div className="button2">
        <ConnectWallet />
      </div> */}

      {/* White Box Small Text */}
      <footer className="d-flex w-100 align-items-center justify-content-between">
        <div className="col-6">
          <h6 className="mx-3">999 HODLERZ RESERVED</h6>
        </div>
        <div className="col-6">
          <h6 className="text-end mx-3">BUY THE DIP</h6>
        </div>
      </footer>
    </div>
  );
}
