import { useState } from "react";
import { Col, Row, Toast, ToastContainer } from "react-bootstrap";
import MintBlack from "../Components/MintBlack";
import MintWhite from "../Components/MintWhite";

export default function Mint() {
  const [show, setShow] = useState(false);

  return (
    <>
      <div>
        <div className="mint container-fluid d-flex align-items-center justify-content-center">
          <div className="row justify-content-center">
            <MintBlack />
            <MintWhite setShow={setShow} />
          </div>
        </div>
        <div>
          <Row>
            <Col xs={6}>
              <ToastContainer position="top-center" containerPosition="fixed">
                <Toast
                  onClose={() => setShow(false)}
                  show={show}
                  delay={3000}
                  autohide
                >
                  <Toast.Header>
                    <img
                      src={`${window.location.origin}/favicon.ico`}
                      height="20"
                      className="rounded me-2"
                      alt=""
                    />
                    <strong className="me-auto">THE HODLERZ</strong>
                    {/* <small>11 mins ago</small> */}
                  </Toast.Header>
                  <Toast.Body className="font_12px">
                    {show && show.toUpperCase()}
                  </Toast.Body>
                </Toast>
              </ToastContainer>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
